<template>
   <div class="main">
      <div class="carousel">
         <v-carousel hide-delimiters height="calc(100vh - 100px)">
            <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src">
               <div class="d-flex justify-center align-end pa-4" style="height: 100%">
                  <v-card class="pa-4 card-effect" max-width="500" elevation="5" color="secondary">
                     <v-card-text>
                        <p class="text-h4 text--primary">{{ item.title }}</p>
                        <div class="text--primary">{{ item.subtitle }}</div>
                     </v-card-text>
                     <v-card-actions>
                        <v-btn v-for="(btn, e) in item.buttons" :key="e" color="primary" @click="$router.push({ name: btn.action })">{{
                           btn.text
                        }}</v-btn>
                     </v-card-actions>
                  </v-card>
               </div>
            </v-carousel-item>
         </v-carousel>
      </div>
   </div>
</template>

<script>
import c from "../const";

export default {
   name: "Main",
   data: () => {
      return {
         items: [
            {
               src: require("../assets/imgs/5.webp"),
               title: `¡Conoce ${c.APP_NAME}!`,
               subtitle: "Plataforma de administración de reportes de servicio",
               buttons: [
                  {
                     text: "Conoce más",
                     action: "Servicios",
                  },
                  {
                     text: "Iniciar sesión",
                     action: "Login",
                  },
               ],
            },
            {
               src: require("../assets/imgs/6.webp"),
               title: "Eficiencia",
               subtitle: "Crea reportes de servicio, consulta, administra clientes",
               buttons: [
                  {
                     text: "¿Cómo funciona?",
                     action: "About",
                  },
               ],
            },
            {
               src: require("../assets/imgs/7.webp"),
               title: "¡Excelente plataforma!",
               subtitle: "¡A un fantástico precio!",
               buttons: [
                  {
                     text: "Contáctanos",
                     action: "Contacto",
                  },
               ],
            },
         ],
      };
   },
};
</script>
